<template>
  <div id="newsStoryContent">
    <p id="Text1">
      Today marks the start of a new adventure! LEGO® Universe just launched a new
      <router-link to="/freetoplay/freetoplay">Free to Play</router-link>
      option. You can now download and play around in the game’s first two zones for free!
    </p>
    <p>
      The Nexus Force is powerful, but they need new recruits! Now, you can answer the call, and join the Nexus Force in the good fight to protect Imagination!
    </p>
    <p align="center">
      <!-- pic2EA3DFC6E33D1DA37C7E8AA3A68E42F8.jpg -->
      <img src="@/assets/news-network/play-lu-for-free-now.jpg" class="rounded">
    </p>
    <p>
      Experience all this and more in LEGO Universe for free:
    </p>
    <p>
      • Create and customize your own LEGO Universe hero<br>
      • Collect up to 10,000 coins<br>
      • Earn U-Score to level up<br>
      • Explore the Starship Venture Explorer<br>
      • Explore Avant Gardens<br>
      • Join the Nexus Force<br>
      • Sample powerful Faction Gear<br>
      • Play the Survival mini-game<br>
      • Battle the Spider Queen<br>
      • Build and bring your creations to life on your own Block Yard Property<br>
      • Visit thousands of other players’ Properties<br>
      • Buy and collect cool gear and useful items
    </p>
    <p>
      There is so much to explore, but don’t worry: You can play for free for as long as you like! There is no time limit!
    </p>
    <p>
      And if that is not enough, you can upgrade to a full membership at any time for full access to the entire game. Full membership comes with incredible benefits like:
    </p>
    <p>
      • Create up to 4 minifigures on each server<br>
      • Stand out with a customized name for your hero<br>
      • Trade with other players<br>
      • Become Best Friends with other players<br>
      • Increase your Friends List to 50 Friends<br>
      • Send unlimited Friend Requests<br>
      • Mail items or models to other players<br>
      • Collect an unlimited amount of coins<br>
      • Team up with other Players to take on bigger challenges!<br>
      • Chat with other players in Local or Team Chat and Whisper<br>
      • Earn U-Score to Level Up to 40, with cool rewards<br>
      • Claim and build on Properties throughout LEGO Universe and visit thousands of other players’ Properties! Access to all Properties in the Nexus:<br>
      <span class="indent"></span> o Block Yard<br>
      <span class="indent"></span> o Nimbus Rock<br>
      <span class="indent"></span> o Raven Bluff<br>
      <span class="indent"></span> o Chantey Shanty<br>
      <span class="indent"></span> o Avant Grove<br>
      <span class="indent"></span> o Nimbus Isle<br>
      • Collect almost a thousand different models to place on Properties<br>
      • Collect almost two thousand unique LEGO bricks! If you can imagine it, you can build it!<br>
      • Unlock advanced behaviors to bring your creations to life!<br>
      • Visit <i>all</i>
       of the worlds of LEGO Universe:<br>
      <span class="indent"></span> o Return to the Venture Explorer - Battle new Hammer Stromlings and more to help Epsilon Starcracker!<br>
      <span class="indent"></span> o Nimbus Station - Join a Faction and buy your own Faction Gear!<br>
      <span class="indent"></span> o Pet Cove - Unlock Pet Taming and tame over 20 Pets throughout the Nexus!<br>
      <span class="indent"></span> o Starbase 3001 - Visit the amazing worlds created by the World Builder League!<br>
      <span class="indent"></span> o Gnarled Forest - Find the inner Pirate in ye! Parrots, Mermaids, Scurvy cads and more await ye!<br>
      <span class="indent"></span> o Forbidden Valley - Get to the top of the Great Tree by using your Ninja wits! Use your stealth and speed to take on the Forbidden Valley Dragons!<br>
      <span class="indent"></span> o Nexus Tower - Meet the Faction Heroes, store items in your personal Vault and buy Valiant Weapons and Capes!<br>
      <span class="indent"></span> o Crux Prime - Meet Sensei Wu, unlock the power of Spinjitzu and battle Ninjago Skeletons!<br>
      • Play all of the fun mini-games throughout the various worlds!<br>
      <span class="indent"></span> o Race in the Vertigo Loop, Keelhaul Canyon and Dragonmaw Chasm Racetracks!<br>
      <span class="indent"></span> o Take aim and fire at the Maelstrom in the Cannon Cove Shooting Gallery!<br>
      <span class="indent"></span> o Go back in time and experience the Battle of Nimbus Station!<br>
      • Eligible to compete in Members-only weekly and monthly contests to win cool LEGO sets!<br>
      • Eligible to join the World Builder League by invitation only. Impress our WBL Recruiter with your Properties!<br>
      • Explore <i>all new areas and activities that the Nexus Force discovers throughout the years!</i>
    </p>
    <p>
      If you haven’t joined the exciting adventure in LEGO Universe yet, now is the time! Join the Nexus Force and help save Imagination! Build, Battle and Play to keep the chaotic Maelstrom at bay! Answer the call,
      <router-link to="/freetoplay/freetoplay">download LEGO Universe for free </router-link>
      and start your online adventure today!
    </p>
    <p>
      Got any questions? We would love to hear your thoughts and questions on the
      <router-link to="/messageboards/4124137">message boards</router-link>!
      Welcome aboard, Explorer!
    </p>
  </div>
</template>

<style scoped lang="scss">
  .indent {
    display: inline-block;
    width: 1em;
  }
</style>
